<template>
  <!-- 客户信息 -->
  <div class="equipment_information">
    <!-- 板块中心区域 -->
    <div class="equipmentInfor_mid" v-loading="loading">
      <div class="equipmentInfor_header">
        <!-- 标题 -->
        <div class="equipment_title">
          <p>{{inform.nname}}</p>
          <div class="gobackImg">
            <img src="../../../assets/image/user_detail/group_140.png" alt="" @click="goback">
          </div>
        </div>
        <div class="equipment_content">
          <p>设备名称<span>{{inform.mname}}</span></p>
          <p>设备编号<span>{{inform.nmid}}</span></p>
          <p>安装区域<span>{{inform.marea ? inform.marea + '/' : '-'}}</span><span>{{inform.mfloor ? inform.mfloor + '/' : '-'}}</span><span>{{inform.mceng}}</span></p>
          <!-- 安装地址需要等请求接口时才能得到 -->
          <p>安装地址<span>{{ inform.maddr }}</span></p>
          <p>设备型号<span>{{$netSeries(inform.nid)}}</span></p>
          <p>设备类型<span>{{ inform.ism == 2 ? '微断' : inform.ism == 3 ? '塑壳' : '未知' }}</span></p>
          <p>P数<span>{{inform.ips + 'P'}}</span></p>
          <p>漏电保护<span>{{inform.iso == 1 ? "带漏保" : '无漏保'}}</span></p>
          <p>进/出线规格(mm)<span>{{inform.under + '/' + inform.out}}</span></p>
          <p>安装时间<span>{{inform.time}}</span></p>
          <p>更新时间<span>{{inform.btime}}</span></p>
          <p>所属网关<span>{{inform.nname}}</span></p>
          <p>网关编号<span>{{inform.nid}}</span></p>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="equipmentInfor_form">
       <h3>网关详情</h3>
       <!-- 版心区域 -->
       <div class="equipment_center">
          <!-- 版心区域--表单 -->
          <div class="center_form">
            <el-form :model="inform"  label-width="100px" class="demo-ruleForm">
              <!-- 网关名称 -->
              <el-form-item label="网关名称" prop="nname">
                <el-input v-model="inform.nname" :disabled="true"></el-input>
              </el-form-item>
              <!-- 网关编号 -->
              <el-form-item label="网关编号" prop="nid">
                <el-input v-model="inform.nid" :disabled="true"></el-input>
              </el-form-item>
              <!-- 拥有回路 -->
              <el-form-item label="拥有回路" prop="mnum">
                <el-input v-model="inform.mnum" :disabled="true"></el-input>
              </el-form-item>
           </el-form>
          </div>
          <!-- 版心区域--网关 -->
          <div class="center_gateWay">
            <div class="gateWay" v-for="item in mach" :key="item.id">
              <!-- 左边 -->
              <div class="gateWay_left">
                <img src="../../../assets/image/equipmentDetail/group_321.png" alt="">
                <!-- <img src="../../../assets/image/equipmentDetail/group_322.png" alt=""> -->
              </div>
              <!-- 右边 -->
              <div class="gateWay_right">
                <h4>{{item.mname}}</h4>
                <p class="net">回路编号:<span>{{item.nmid}}</span></p>
                <p>安装日期:<span class="riqi">{{item.time  ? item.time : '无'}}</span></p>
                <p>漏电保护:<span>{{item.iso == 1 ? "带漏保" : '无漏保'}}</span></p>
              </div>
            </div>
          </div>
       </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
import { deviceDetail, gateWayDeviceList } from "@/utils/api";

export default {

  components: {
    
  },
  data () {
    return {
      inform:{},//公司信息
      mach:[],//回路数量
      nid:{},//网关信息
      loading: true
    };
  },
  computed: {
    ...mapState('equipmentDetail',['gateWayDetail'])
  },
 created () {
   this.getDetail()
 },
  methods: {
    async getDetail() {
      this.loading = true
      const res = await deviceDetail({nmid: this.$route.query.nmid});
      this.inform = res.data
      this.inform.nname = this.$route.query.nname
      this.getList(this.inform.nid)
    },
    async getList(nid) {
      const res = await gateWayDeviceList(({nid}));
      this.mach = res.data.data
      this.inform.mnum = res.data.total
      this.loading = false
    },
    goback(){
      this.$router.go(-1)
    }
  },
};
</script>

<style  scoped>
.equipment_information{
}
.equipment_information .equipmentInfor_mid {
  width: 100%;
  margin: -30px 0 0 0 ;
}

/* 头部--开始 */
.equipmentInfor_mid .equipmentInfor_header {
  width: 100%;
  height: 176px;
  background-color: #fff;
}
/* 关联公司和返回 */
.equipment_title{
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5% 0 36px;
}
.gobackImg {
  margin-right: 20px;
  cursor: pointer;
}
.equipment_title > p{
  font-family: "SHSCN_Bold";
  font-size: 18px;
}
.equipment_content{
  height: calc(100% - 35%);
  padding-left:36px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
}
.equipment_content p{
  font-family: "SHSCN_Regular";
  font-size: 14px;
  color:#ABABAB;
  word-wrap:break-word;
  word-break:normal; 
}
.equipment_content span{
  color: #171717;
  display: inline-block;
  margin: 0 0 0 4%;
}

/* 头部--结束 */

/* 表格区域--开始 */
.equipmentInfor_mid .equipmentInfor_form {
  width: 100%;
  height: calc(100vh - 290px);
  overflow-y: auto;
  background-color: #fff;
  padding-top: 28px;
  margin-top: 8px;
}
.equipmentInfor_form h3{
  font-family: 'SHSCN_Bold';
  color: #171717;
  font-size:18px;
  margin-left:28px;
}
/* 版心区域 */
.equipmentInfor_form .equipment_center{
  width: 90%;
}
/* 版心区域--表单 */
.center_form /deep/ .el-form-item {
  margin-top: 15px;
}
.center_form /deep/ .el-input__inner{
  height: 32px;
}
.equipment_center .center_form .el-form{
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    font-family: 'SHSCN_Regular';
    justify-content: space-between;
}
.center_gateWay{
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  justify-items: center;
  margin-left: 28px;
  margin-bottom: 20px;
}
.gateWay{
  width: 85%;
  background-color: #fff;
  display: flex;
  background-color: #F1F3F8;
  border-radius:3%;
  margin:28px 50px 0 48px;
}
/* 左边开始 */
.gateWay .gateWay_left{
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  justify-content: center;
}
.gateWay .gateWay_left img:nth-child(1){
  width: 24.29px;
  margin-bottom: 15%;
}
.gateWay .gateWay_left img:nth-child(2){
  width: 35px;
}
/* 左边结束 */
/* 右边开始 */
.gateWay .gateWay_right{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  margin: 5% 0 4% 0;
}
.gateWay .gateWay_right h4{
  font-family: "SHSCN_Bold";
  color: #171717;
}
.gateWay .gateWay_right p{
  font-size: 14px;
  font-family: 'SHSCN_Regular';
  margin-top: 10%;
  color: #171717;
}
.gateWay_right span{
  font-size: 12px;
}
/* 右边结束 */
</style>